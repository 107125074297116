import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoaModalComponent } from './coa-modal/coa-modal.component';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzFormModule } from 'ng-zorro-antd/form';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@NgModule({
  imports: [
    CommonModule,
    NzModalModule,
    NzFormModule,
    FormsModule,
    ReactiveFormsModule,
    NzInputModule,
    NzButtonModule,
    PdfViewerModule,
  ],
  declarations: [CoaModalComponent, PdfViewerComponent],
  exports: [CoaModalComponent, PdfViewerComponent],
})
export class SharedModule {}
