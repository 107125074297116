<!-- Main layout container -->
<nz-layout>
  <!-- Header section -->
  <nz-header class="header">
    <!-- Logo and title -->
    <div class="logo">
      <img src="/assets/Logo.svg" alt="Logo Image" />
      <div class="logo-text">Builtdesign</div>
    </div>
  </nz-header>
  <nz-layout class="inner-layout">
    <!-- Child routes -->
    <app-spinner class="app-spinner"></app-spinner>
    <router-outlet></router-outlet>
  </nz-layout>
</nz-layout>
