<!-- Main layout container -->
<nz-layout class="main-auth-layout">
  <div nz-row>
    <div nz-col nzXs="24" nzSm="24" nzMd="10">
      <nz-layout class="auth-container">
        <nz-content>
          <!-- Header section -->
          <nz-header>
            <!-- Logo and title -->
            <div class="auth-logo">
              <img src="/assets/Logo.svg" alt="Logo Image" />
              <div class="auth-logo-text">Builtdesign</div>
            </div>
          </nz-header>
          <!-- Child routes -->
          <router-outlet></router-outlet>
        </nz-content>
        <!-- Footer section : with copyright and help email -->
        <nz-footer>
          <div nz-row nzJustify="space-between">
            <div nz-col>&copy; builtdesign</div>
            <div nz-col>
              <span nz-icon nzType="mail" nzTheme="outline"></span>
              <a class="mail" href="mailto:info@builtdesign.in">info@builtdesign.in</a>
            </div>
          </div>
        </nz-footer>
      </nz-layout>
    </div>
    <!-- Column for quote box -->
    <div nz-col nzXs="0" nzSm="0" nzMd="14" nz class="quote-box">
      <h1>
        Design is not just what it looks like and feels like. Design is how it works. Your journey to shaping the world
        starts here.
      </h1>
      <div nz-row nzJustify="space-between">
        <div nz-col>
          <div class="author-name">— Steve Jobs</div>
          <!-- <span class="author-designation">Founder, Layers.io</span> -->
        </div>
        <div nz-col>
          <span nz-icon nzType="star" nzTheme="fill" style="font-size: 18px; color: #d91e18"></span>
          <span nz-icon nzType="star" nzTheme="fill" style="font-size: 18px; color: #d91e18"></span>
          <span nz-icon nzType="star" nzTheme="fill" style="font-size: 18px; color: #d91e18"></span>
          <span nz-icon nzType="star" nzTheme="fill" style="font-size: 18px; color: #d91e18"></span>
          <span nz-icon nzType="star" nzTheme="fill" style="font-size: 18px; color: #d91e18"></span>
        </div>
      </div>
    </div>
  </div>
</nz-layout>
