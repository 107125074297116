import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardLayoutComponent } from './layouts/dashboard/dashboard-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { PageNotFoundComponent } from './layouts/404/404.component';
import { AuthGuard } from './services/auth-guard.service';
import { DashGuard } from './services/dash-guard.service';
import { ArchitectProfileComponent } from './layouts/architect-profile/architect-profile-layout.component';

export const routes: Routes = [
  {
    path: '',
    component: DashboardLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./dashboard-components/dashboard.module').then((m) => m.DashboardModule),
      },
    ],
  },
  {
    path: '',
    component: AuthLayoutComponent,
    canActivate: [DashGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./auth-components/auth.module').then((m) => m.AuthModule),
      },
    ],
  },
  {
    path: '',
    component: ArchitectProfileComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./architect-profile-component/architect-profile.module').then((m) => m.ArchitectProfileModule),
      },
    ],
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  //Wild Card Route for 404 request
  {
    path: '**',
    pathMatch: 'full',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
