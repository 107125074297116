import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth-layout',
  styleUrls: ['./auth-layout.component.scss'],
  templateUrl: './auth-layout.component.html',
})
export class AuthLayoutComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {}
}
