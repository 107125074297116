import { Injectable } from '@angular/core';
import { ShepherdService } from 'angular-shepherd';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class TourService {
  private readonly tourKey = 'tourCompleted';

  constructor(private shepherdService: ShepherdService, private router: Router) {}

  public startTour(): void {
    this.shepherdService.defaultStepOptions = {
      scrollTo: true,
      cancelIcon: {
        enabled: true,
      },
      canClickTarget: true,
    };

    // Get the current route
    const currentRoute = this.router.url;
    // Determine which tour steps to show based on the route
    const tourSteps = this.getTourSteps(currentRoute);
    this.shepherdService.addSteps(tourSteps);

    this.shepherdService.tourObject.on('cancel', () => this.setTourCompleted());
    // Listen for when the tour is completed
    this.shepherdService.tourObject.on('complete', () => this.setTourCompleted());
    this.shepherdService.start();
  }

  private getTourSteps(route: string): any {
    switch (route) {
      case '/home':
        return [
          {
            id: 'step1',
            title: '<strong>Welcome to Builtdesign!</strong>',
            text: 'This is your dashboard where you can manage all your activities. From tracking bids to overseeing ongoing projects, everything you need is right here.',
            attachTo: {
              element: '.step1',
              on: 'right',
            },
            buttons: [
              {
                text: 'Skip',
                secondary: 'true',
                action: () => this.shepherdService.complete(),
              },
              {
                text: 'Next',
                action: () => this.shepherdService.next(),
              },
            ],
          },
          {
            id: 'step2',
            title: '<strong>Profile</strong>',
            text: ' Here, you can view and update your personal information, including your name, contact details and portfolio.',
            attachTo: {
              element: '.step2',
              on: 'right',
            },
            buttons: [
              {
                text: 'Back',
                secondary: true,
                action: () => this.shepherdService.back(),
              },
              {
                text: 'Next',
                action: () => this.shepherdService.next(),
              },
            ],
          },
          {
            id: 'step3',
            title: '<strong>Explore Projects</strong>',
            text: 'This is where you can explore new projects and manage your applied projects.',
            attachTo: {
              element: '.step3',
              on: 'right',
            },
            buttons: [
              {
                text: 'Back',
                secondary: true,
                action: () => this.shepherdService.back(),
              },
              {
                text: 'Next',
                action: () => this.shepherdService.next(),
              },
            ],
          },
          {
            id: 'step4',
            title: '<strong>My Projects</strong>',
            text: 'In the My Projects section, you’ll find all the Design Proposal Projects (DPP) you’re currently working on. ',
            scrollTo: false,
            attachTo: {
              element: '.step4',
              on: 'bottom-start',
            },
            buttons: [
              {
                text: 'Back',
                secondary: true,
                action: () => this.shepherdService.back(),
              },
              {
                text: 'Next',
                action: () => this.shepherdService.next(),
              },
            ],
          },
          {
            id: 'step5',
            title: '<strong>My Jobs</strong>',
            text: 'The My Jobs section is where you manage all your ancillary service work. These are smaller tasks or freelance services that you’ve been hired to complete outside of the main competition-based projects.',
            scrollTo: false,
            attachTo: {
              element: '.step5',
              on: 'bottom-start',
            },
            buttons: [
              {
                text: 'Back',
                secondary: true,
                action: () => this.shepherdService.back(),
              },
              {
                text: 'Next',
                action: () => this.shepherdService.next(),
              },
            ],
          },
          {
            id: 'step6',
            title: '<strong>Provide your valuable Feedback</strong>',
            text: 'Using this section you can provide your valuable feedback on where we can improve.',
            attachTo: {
              element: '.step6',
              on: 'bottom',
            },
            buttons: [
              {
                text: 'Back',
                secondary: true,
                action: () => this.shepherdService.back(),
              },
              {
                text: 'Next',
                action: () => this.shepherdService.next(),
              },
            ],
          },
          {
            id: 'step7',
            title: '<strong>View Notifications</strong>',
            text: 'You can view various notifications here about various activities of yours.',
            attachTo: {
              element: '.step7',
              on: 'bottom',
            },
            buttons: [
              {
                text: 'Back',
                secondary: true,
                action: () => this.shepherdService.back(),
              },
              {
                text: 'Next',
                action: () => this.shepherdService.next(),
              },
            ],
          },
          {
            id: 'step8',
            title: '<strong>Customer Support</strong>',
            text: 'In case of any support you need we are here to help you. Please let us know it and our team member will contact you.',
            attachTo: {
              element: '.step8',
              on: 'bottom',
            },
            buttons: [
              {
                text: 'Back',
                secondary: true,
                action: () => this.shepherdService.back(),
              },
              {
                text: 'Next',
                action: () => {
                  this.router.navigateByUrl('/project');
                  // this.shepherdService.show('project-step1');
                  this.shepherdService.hide();
                },
              },
            ],
          },
          {
            id: 'project-step1',
            title: 'Explore Projects',
            text: 'In the Explore Projects section, you can browse through a list of new and available projects that match your expertise.',
            scrollTo: false,
            attachTo: {
              element: '.project-step1',
              on: 'bottom-start',
            },
            buttons: [
              {
                text: 'Next',
                action: () => this.shepherdService.next(),
              },
            ],
          },
          {
            id: 'project-step2',
            title: 'This is applied projects section.',
            text: "In the Applied Projects section, you can track the status of the projects you've applied to.",
            scrollTo: false,
            attachTo: {
              element: '.project-step2',
              on: 'bottom-start',
            },
            buttons: [
              {
                text: 'Back',
                action: () => this.shepherdService.back(),
              },
              {
                text: 'Finish',
                action: () => {
                  this.shepherdService.complete();
                  this.router.navigate(['/home']);
                  this.setTourCompleted();
                },
              },
            ],
          },
        ];

      default:
        return []; // No tour for unknown routes
    }
  }

  private setTourCompleted(): void {
    localStorage.setItem(this.tourKey, 'true');
  }

  public isTourCompleted(): boolean {
    return localStorage.getItem(this.tourKey) === 'true';
  }
}
