import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ApiService } from 'src/app/services/api.service';
import { DataShareService } from 'src/app/services/data-share.service';
import { ApiConfig } from 'src/app/utilities/configuration/apiConfig';
import { CoaStatus } from 'src/app/utilities/configuration/enum';

@Component({
  selector: 'app-coa-modal',
  templateUrl: './coa-modal.component.html',
  styleUrls: ['./coa-modal.component.scss'],
})
export class CoaModalComponent {
  @Input() isCoaModalVisible = false;
  @Output() isCoaModalVisibleChange: EventEmitter<any> = new EventEmitter<any>();
  isCoaConfirmLoading = false;
  coaForm: FormGroup;
  user: any = {};
  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private notification: NzNotificationService,
    private dataShareService: DataShareService,
  ) {
    this.coaForm = this.fb.group({
      COA_name: new FormControl(this.user?.coa_details?.name, Validators.required),
      COA_number: new FormControl(this.user?.coa_details?.number, Validators.required),
    });
    this.dataShareService.getProfileDataData().subscribe((profileData) => {
      if (Object.keys(profileData).length !== 0) {
        this.user = profileData;
        this.coaForm.patchValue({
          COA_name: this.user.coa_details?.coa_name,
          COA_number: this.user.coa_details?.coa_number,
        });
      }
    });
  }

  handleCoaCancel() {
    this.isCoaModalVisible = false;
    this.isCoaModalVisibleChange.emit(this.isCoaModalVisible);
  }

  addCOADetails() {
    this.isCoaConfirmLoading = true;
    const coaFormData = this.coaForm.getRawValue();
    const payload = {
      coa_details: {
        coa_name: coaFormData.COA_name,
        coa_number: coaFormData.COA_number,
        coa_verification_status: CoaStatus.pending,
      },
    };

    this.apiService.patchRequest(`${ApiConfig.urlConstant.user}/${this.user?._id}`, payload).subscribe({
      next: (result: any) => {
        const { data } = result;
        if (data) {
          localStorage.setItem('profileData', JSON.stringify(data));
          this.isCoaModalVisible = false;
          this.isCoaConfirmLoading = false;
          // this.getProfileDetails();
          // this.profileData = data;
          this.dataShareService.setProfileDataData(data);
          // this.setProfileDetails();
        }
      },
      error: (err) => {
        this.notification.create('error', 'Error!', `${err?.message}`);
      },
      complete: () => {},
    });
  }
}
