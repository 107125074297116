export enum MetadataTypeEnum {
  SKILLS = 1,
  WORK_CATEGORY = 2,
  PROJECT_TYPE = 3,
  SOFTWARE_SKILLS = 4,
}

export enum badegesEnum {
  Onboarding = 1,
  Application = 2,
  Worker = 3,
  Professional = 4,
}

export enum CoaEnum {
  PENDING = 1,
  ACCEPTED = 2,
  REJECTED = 3,
}

export enum BidStatus {
  UNDER_REVIEW = 1,
  ADMIN_APPROVED = 2,
  ADMIN_REJECTED = 3,
  APPROVED = 4,
  DECLINED = 5,
  HOLD = 6,
}

export enum JobType {
  ALL_JOBS = 1,
  APPLIED_JOBS = 2,
  AVAILABLE_JOBS = 3,
}

export enum SupportType {
  JOB_RELATED = 'Job Related',
  ACCOUNT_RELATED = 'Account Related',
  PAYMENT_RELATED = 'Payment Related',
  OTHERS = 'Others',
}

export enum BidSubmissionStatus {
  UNDER_REVIEW = 1,
  ADMIN_APPROVED = 2,
  CLIENT_APPROVED = 3,
  CLIENT_REJECTED = 4,
  DECLINED = 5,
}

export enum JobStatus {
  INITIATED = 1,
  UNDER_REVIEW = 2,
  APPROVED = 3,
  IN_PROGRESS = 4,
  COMPLETED = 5,
  DECLINED = 6,
  HOLD = 7,
}

export enum CoaStatus {
  initiated = 0,
  pending = 1,
  verified = 2,
}
