import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataShareService {
  profileData = new BehaviorSubject<any>({});

  setProfileDataData(data: any) {
    this.profileData.next(data);
  }

  getProfileDataData(): Observable<any> {
    return this.profileData.asObservable();
  }
}
