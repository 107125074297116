import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-404',
  styleUrls: ['./404.component.scss'],
  templateUrl: './404.component.html',
})
export class PageNotFoundComponent {
  // Constructor with injected services
  constructor(private _location: Location) {}

  // Method to navigate to the previous page
  backClicked() {
    this._location.back();
  }
}
