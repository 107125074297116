export class ApiConfig {
  static readonly urlConstant = {
    profile: '/profile',
    metaData: '/utils/metadata',
    user: '/user',
    userProfile: '/user/profile',
    jobs: '/jobs',
    bid: '/bid',
    support: '/support',
    feedback: '/support/feedback',
    notification: '/notification',
    services: '/services',
    forum: '/forum',
    userDashboard: '/user-dashboard',
  };
}
