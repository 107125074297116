<button nz-button nzType="primary" nzSize="large" nzShape="round" (click)="showModal()">
  <span nz-icon nzType="file-pdf" style="color: #ffffff"></span>
  View PDF
</button>
<nz-modal
  [nzVisible]="previewVisible"
  [nzContent]="modalContent"
  [nzFooter]="null"
  (nzOnCancel)="handleCancel()"
  [nzStyle]="{ width: '90vw', top: '20px' }"
>
  <ng-template #modalContent>
    <pdf-viewer
      [src]="data.url"
      [render-text]="true"
      [original-size]="false"
      style="width: 85vw; height: 85vh"
    ></pdf-viewer>
  </ng-template>
</nz-modal>
