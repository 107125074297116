<nz-modal
  [(nzVisible)]="isCoaModalVisible"
  nzTitle="COA Details"
  (nzOnCancel)="handleCoaCancel()"
  [nzOkLoading]="isCoaConfirmLoading"
  [nzContent]="modalContent"
  [nzFooter]="modalFooter"
>
  <ng-template #modalContent>
    <form nz-form nzLayout="vertical" [formGroup]="coaForm">
      <nz-form-item>
        <nz-form-label nzRequired>Name on COA Registration : </nz-form-label>
        <nz-form-control nzHasFeedback nzErrorTip="Please enter COA registration name!">
          <input
            type="text"
            nz-input
            nzPlaceHolder="Enter Name present on COA Registration."
            formControlName="COA_name"
          />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label nzRequired>COA Registration Number : </nz-form-label>
        <nz-form-control nzHasFeedback nzErrorTip="Please enter your COA lincense number!">
          <input
            type="text"
            nz-input
            nzPlaceHolder="If applicable, enter your COA license number."
            formControlName="COA_number"
          />
        </nz-form-control>
      </nz-form-item>
    </form>
  </ng-template>
  <ng-template #modalFooter>
    <button nz-button nzType="primary" nzShape="round" (click)="addCOADetails()" [disabled]="!coaForm.valid">
      <span>Save</span>
    </button>
  </ng-template>
</nz-modal>
