import { Component, HostListener, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CognitoService } from './../../services/cognito.service';
import { catchError, of, Subscription } from 'rxjs';
import { DataShareService } from 'src/app/services/data-share.service';
import { Keys } from 'src/app/utilities/configuration/keys';
import { ApiService } from 'src/app/services/api.service';
import { ApiConfig } from 'src/app/utilities/configuration/apiConfig';
import { HttpErrorResponse } from '@angular/common/http';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { MetadataTypeEnum } from 'src/app/utilities/configuration/enum';
import { BooleanInput } from 'ng-zorro-antd/core/types';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { calculateTime } from 'src/app/utilities/timeAgo';
import { TourService } from 'src/app/services/tour.service';

interface INotification {
  _id: string;
  title: string;
  description: string;
  is_read: boolean;
}
@Component({
  selector: 'app-dashboard-layout',
  styleUrls: ['./dashboard-layout.component.scss'],
  templateUrl: './dashboard-layout.component.html',
})
export class DashboardLayoutComponent implements OnDestroy {
  // Constructor with injected services
  userSubId: any = '';
  profileDataSubscription!: Subscription;
  profileData: any;
  isCollapsed: BooleanInput;
  isSmallScreen: boolean = false;
  isModalOpen: boolean = false;
  issueType: string = '';
  contactForm: FormGroup;
  supportUrl: string;
  notificationUrl: string;
  notifications: INotification[] = [];
  showViewAll: boolean = false;
  isCoaModalVisible: boolean = false;
  isNotificationPanelVisible: boolean = false;
  unreadCount: number = 0;
  feedbackForm: FormGroup;
  isFeedbackModalOpen: boolean = false;
  feedbackUrl: string;
  userUrl: string;
  loadingMore: boolean = false;
  hasMoreNotifications: boolean = false;
  offset: number = 1;

  public metadataTypeEnum = MetadataTypeEnum;
  metaData: any = [];
  constructor(
    private cognitoService: CognitoService,
    private router: Router,
    private dataShareService: DataShareService,
    private apiService: ApiService,
    private notification: NzNotificationService,
    private fb: FormBuilder,
    private tourService: TourService,
  ) {
    this.userSubId = localStorage.getItem(Keys.USER_SUB) || '';
    this.getProfileDetails();
    this.getAllMetaData();
    this.profileDataSubscription = this.dataShareService.getProfileDataData().subscribe((profileData) => {
      this.profileData = profileData;
    });
    this.contactForm = this.fb.group({
      type: ['', Validators.required],
      description: ['', Validators.required],
    });

    this.feedbackForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      feedback_text: ['', Validators.required],
    });

    this.supportUrl = ApiConfig.urlConstant.support;
    this.notificationUrl = ApiConfig.urlConstant.notification;
    this.feedbackUrl = ApiConfig.urlConstant.feedback;
    this.userUrl = ApiConfig.urlConstant.user;
  }

  ngOnInit(): void {
    this.checkScreenSize();
    Promise.all([this.getAllNotification(), this.getUnreadNotificationCount()])
      .then(() => {
        if (!this.tourService.isTourCompleted()) this.tourService.startTour();
      })
      .catch((e) => console.log(e));

    this.apiService.patchRequest(this.userUrl + '/last-login/' + this.userSubId).subscribe({
      next: (result) => {
        const { success, data } = result;
      },
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.checkScreenSize();
  }

  checkScreenSize(): void {
    this.isSmallScreen = window.innerWidth < 768;
  }

  // Method to handle user sign out
  signOut() {
    this.cognitoService.handleSignOut().then((res: any) => {
      this.router.navigate(['/login']);
      localStorage.clear();
    });
  }

  // Method to navigate to the home page
  navigateToHome() {
    this.router.navigate(['/home']);
  }
  getProfileDetails(): void {
    // let query: string = `?limit=${10}&offset=${1}`;
    // this.profileService.getAllProfiles(query).subscribe({
    // const ID = '652fbfc9a4dd162bdd1fbcb9';
    const ID = this.userSubId;
    this.apiService.getRequest(`${ApiConfig.urlConstant.user}/${ID}`).subscribe({
      next: (result: any) => {
        const { success, data } = result;
        if (data) {
          localStorage.setItem(Keys.PROFILE_DATA, JSON.stringify(data));
          this.dataShareService.setProfileDataData(data);
        } else {
          this.notification.info('Info', 'No users data!');
        }
      },
      error: (err: HttpErrorResponse) => {
        this.notification.create('error', 'Error!', `${err?.message}`);
      },
      complete: () => {},
    });
  }

  getAllMetaData() {
    const urlConst = `?limit=${200}`;

    this.apiService.getRequest(ApiConfig.urlConstant.metaData + urlConst).subscribe({
      next: (result: any) => {
        const { success, data } = result;

        if (data) {
          this.metaData = data.data;
        } else {
          this.notification.info('Info', 'No record for meta data!');
        }
      },
      error: (err: HttpErrorResponse) => {
        this.notification.error('Error', err?.message);
      },
      complete: () => {
        let dummyMetaData: any = {
          skills: [],
          workCategories: [],
          projectTypes: [],
          softwareSkills: [],
        };

        this.metaData.forEach((data: any) => {
          switch (data.type) {
            case this.metadataTypeEnum.SKILLS:
              dummyMetaData.skills.push(data);
              break;
            case this.metadataTypeEnum.WORK_CATEGORY:
              dummyMetaData.workCategories.push(data);
              break;
            case this.metadataTypeEnum.PROJECT_TYPE:
              dummyMetaData.projectTypes.push(data);
              break;
            case this.metadataTypeEnum.SOFTWARE_SKILLS:
              dummyMetaData.softwareSkills.push(data);
              break;
          }
        });
        localStorage.setItem(Keys.META_DATA, JSON.stringify(dummyMetaData));
      },
    });
  }

  makeQuery() {
    const payload = this.contactForm.value;
    this.apiService.postRequest(this.supportUrl, { ...payload }).subscribe({
      next: (result) => {
        const { data, success } = result;
        if (data) {
          this.isModalOpen = false;
          this.notification.success('Success', 'Your query raised successfully!');
          this.contactForm.reset();
        }
      },
    });
  }

  handleSidebar() {
    if (this.isSmallScreen) this.isCollapsed = true;
  }

  ngOnDestroy(): void {
    if (this.profileDataSubscription) {
      this.profileDataSubscription.unsubscribe();
    }
  }

  handleModalClose() {
    this.isModalOpen = false;
  }

  handleModalOpen() {
    this.isModalOpen = true;
  }

  showCoaModal() {
    this.isCoaModalVisible = true;
  }

  onSubmit() {}

  getAllNotification() {
    this.apiService
      .getRequest(this.notificationUrl, {
        params: {
          limit: 5,
          sortOrder: 'DESC',
        },
      })
      .subscribe({
        next: (result) => {
          const { data, success } = result;
          if (data) {
            this.notifications = data?.data;
            if (data?.total > 5) this.showViewAll = true;
            this.hasMoreNotifications = data?.hasNextPage;
          }
        },
      });
  }

  markAllAsRead() {
    this.apiService.patchRequest(`${this.notificationUrl}/mark-all-as-read`).subscribe({
      next: (result) => {
        const { data, success } = result;
        if (data) {
          this.notifications.forEach((item) => {
            item.is_read = true;
          });
          this.getUnreadNotificationCount();
        }
      },
    });
  }

  onLoadMoreNotifications() {
    this.loadingMore = true;
    this.offset++;
    this.apiService
      .getRequest(`${this.notificationUrl}`, {
        params: {
          offset: this.offset,
          sortOrder: 'DESC',
          limit: 5,
        },
      })
      .pipe(catchError(() => of({ results: [] })))
      .subscribe((res: any) => {
        const { data, success } = res;
        const notificationsSize = this.notifications.length;
        if (this.notifications[notificationsSize - 1]?._id === data.data[0]?._id) {
          this.notifications.pop();
        }
        this.notifications = this.notifications.concat(data.data);
        this.hasMoreNotifications = res?.data?.hasNextPage;
        this.loadingMore = false;
      });
  }

  markAsRead(id: string) {
    this.apiService.patchRequest(`${this.notificationUrl}/${id}/mark-as-read`).subscribe({
      next: (result) => {
        const { data, success } = result;
        if (data) {
          const indexOfReaded = this.notifications.findIndex((item) => item?._id === id);
          this.notifications[indexOfReaded].is_read = true;
          this.getUnreadNotificationCount();
        }
      },
    });
  }

  getUnreadNotificationCount() {
    this.apiService.getRequest(`${this.notificationUrl}/unread-count`).subscribe({
      next: (result) => {
        const { data, success } = result;
        if (data?.unreadCount >= 0) {
          this.unreadCount = data?.unreadCount;
        }
      },
    });
  }

  calculateTimeAgo(date: string) {
    return calculateTime(date);
  }

  postFeedback() {
    const payload = this.feedbackForm.value;
    this.apiService.postRequest(`${this.feedbackUrl}`, { ...payload }).subscribe({
      next: (result) => {
        const { data, success } = result;
        if (data) {
          this.handleFeedbackModalClose();
          this.notification.success('Success', 'Your Feedback is sent successfully!');
          this.feedbackForm.reset();
        }
      },
    });
  }

  handleFeedbackModalClose() {
    this.isFeedbackModalOpen = false;
  }

  openFeedbackModal() {
    this.isFeedbackModalOpen = true;
  }
}
