import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent {
  @Input() data!: any;
  previewVisible: boolean = false;

  constructor() {}

  showModal(): void {
    this.previewVisible = true;
  }

  handleCancel(): void {
    this.previewVisible = false;
  }
}
